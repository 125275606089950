/* website: 3480-spinellikia2
 * created at 2021-09-07 15:30 by fbbreard*/

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";


.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__spinelli-kia{
        /** fix place tradein at top of listing **/
        .listing-used-car-search>div:last-child{
            display: flex;
            flex-direction: column;
            #instant-estimate-bar-wrapper{
                order: 0;
                margin-top: 0px !important;
            }
            .listing-tiles{
                order: 1;
            }
            .listing-used-button-loading.sr-button-1{
                order: 2;
            }
        }
        /** fix AV widget padding on V2 VLP **/
        .listing-used-car-search{
            .av_widget_root{
                margin: 0 0 20px 0 !important;
            }
        }
    }
}